import hyphenated from "./Hyphenated";
import fr from "hyphenated-fr";
import { getCurrentLocale } from "./Locale.js";

export const extractTextFromHtmlString = (htmlString) => {
  if (htmlString) {
    const div = document.createElement("div");
    const html = htmlString.trim(); // Never return a text node of whitespace as the result
    div.innerHTML = html;
    return div.textContent;
  }
  return false;
};

export const hyphenateHtmlString = (html) => {
  const hyphenatedOptions = {};
  if (getCurrentLocale() === "fr") {
    hyphenatedOptions.language = fr;
  }
  const result = hyphenated(html, hyphenatedOptions);

  // post parse - clean tags
  const tagRegex = /<(.*?)>/gi;
  const cleanTag = (correspondance, p1) => `<${p1.replace(/\u00AD/g, "")}>`;
  return result.replace(tagRegex, cleanTag);
};
