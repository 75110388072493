import React, { useEffect } from "react";
import { shape } from "prop-types";
import { News as NewsType, ItemConfig } from "../../types/index.js";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNewsArchives,
  fetchNewsBySlug,
} from "../../actions/contentDataActions";
import { newsArchivesSetYear } from "../../actions/newsActions.js";
import { getCurrentLocale } from "../../utils/Locale";
import ItemKontentText from "../Panel/ItemKontentText.jsx";
import { imageHeight, resolveSeaPicture } from "../../utils/ItemPanel.js";
import Loader from "../Loader.jsx";

const NewsArchives = ({ config, news }) => {
  const dispatch = useDispatch();
  const archives = useSelector(({ contentData: { newsArchives } }) => {
    return newsArchives;
  });
  const currentYear = useSelector(
    ({ contentData: { newsArchivesYear } }) => newsArchivesYear
  );
  const currentNews = useSelector(
    ({ contentData: { newsDetail } }) => newsDetail
  );
  const newsState = useSelector(({ news }) => news);
  const newsDetailPending = useSelector(
    ({ contentData: { newsDetailPending } }) => newsDetailPending
  );

  const { type } = news;
  const clickYear = (year) => (e) => {
    e.preventDefault();
    dispatch(newsArchivesSetYear(year));
  };
  const clickNews = (id) => (e) => {
    e.preventDefault();
    dispatch(fetchNewsBySlug(getCurrentLocale(), null, id));
  };
  useEffect(() => {
    dispatch(fetchNewsArchives(getCurrentLocale(), type));
  }, [type, dispatch]);
  return (
    <div className="NewsArchives">
      <div className="years">
        {archives &&
          Object.keys(archives).map((year) => (
            <a
              key={year}
              className={`alink year ${currentYear === year ? "active" : ""}`}
              href="#"
              onClick={clickYear(year)}
            >
              {year}
            </a>
          ))}
      </div>
      {currentYear &&
        archives[currentYear] &&
        Array.isArray(archives[currentYear]) && (
          <ul className="news-list">
            {archives[currentYear].map((newsItem) => (
              <li key={newsItem.id}>
                <a
                  href="#"
                  onClick={clickNews(newsItem.id)}
                  className={`alink news ${
                    currentNews &&
                    currentNews.id &&
                    currentNews.id === newsItem.id
                      ? "active"
                      : ""
                  }`}
                  id={`article-${currentNews.id}`}
                >
                  {newsItem.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      <div className="bottom">
        {newsDetailPending && (
          <div className="archive-loader">
            <Loader />
          </div>
        )}
        {currentNews && currentNews.body ? (
          <div className="article-panel current-news">
            <ItemKontentText
              item={currentNews}
              panelState={newsState}
              config={config}
            />
          </div>
        ) : (
          <img
            src={resolveSeaPicture(config, news)}
            className="HomePageContentCloseImage"
            style={{
              height: `${imageHeight()}px`,
              maxHeight: "inherit",
            }}
          />
        )}
      </div>
    </div>
  );
};

NewsArchives.propTypes = {
  config: ItemConfig,
  news: shape(NewsType),
};

export default NewsArchives;
