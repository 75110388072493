import React from "react";
import { FormattedMessage } from "react-intl";
import { getCurrentLocale } from "../../utils/Locale.js";
import propTypes from "prop-types";
import logo from "./assets/logo.png";
import logoTitle from "./assets/logo-titre.png";

const HomePageTop = ({ clickMenu, showMenu }) => {
  const homeUrl = `/?lang=${getCurrentLocale()}`;
  const onClickMenu = (menuShowed) => (e) => {
    e.preventDefault();
    clickMenu(menuShowed);
  };
  return (
    <div className="HomePageTop">
      <p className="HomePageIntro">
        <FormattedMessage id="fannac.intro" />
      </p>
      <div className="logo">
        <div className="logoPictureWrapper">
          <a href={homeUrl}>
            <img src={logo} className="logoPicture" />
          </a>
        </div>
        <div className="logoColor2" />
      </div>
      <div className="HomePageTitle">
        <a href={homeUrl} className="logoTitleLink">
          <img src={logoTitle} className="logoTitlePicture" />
        </a>
        <div
          className={`menu-burger ${showMenu ? "opened" : ""}`}
          onClick={onClickMenu(!showMenu)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

HomePageTop.propTypes = {
  clickMenu: propTypes.func,
  showMenu: propTypes.bool,
};

export default HomePageTop;
