import * as types from "../constants/actionTypes";
import { routerConfig } from "../constants/url";
import { goTo } from "./urlActions";
import { findItemFromIdWithParent, findItemFromSlug } from "../utils/Url";
import { fetchNewsBySlug } from "./contentDataActions";
import { getCurrentLocale } from "../utils/Locale";

// example of a thunk using the redux-thunk middleware
export function toggleState(newsId) {
  return function (dispatch) {
    dispatch({
      type: types.NEWS_TOGGLE_STATE,
      newsId,
    });
  };
}

export function toggleExpand(newsId) {
  return function (dispatch) {
    dispatch({
      type: types.NEWS_TOGGLE_EXPAND,
      newsId,
    });
  };
}

export function closeAll() {
  return function (dispatch) {
    dispatch({
      type: types.NEWS_CLOSE_ALL,
    });
  };
}

export function newsOpenInit() {
  return function (dispatch) {
    dispatch({
      type: types.NEWS_OPEN_INIT,
    });
  };
}

export function newsOpenFromIds(newsIds) {
  return function (dispatch) {
    dispatch({
      type: types.NEWS_OPEN_SUCCESS,
      newsIds,
    });
  };
}

export function newsOpenError(error) {
  return function (dispatch) {
    dispatch({
      type: types.NEWS_OPEN_ERROR,
      error,
    });
  };
}

export function newsOpen(slug, id) {
  return function (dispatch, getState) {
    const {
      contentData: {
        news: { children },
      },
    } = getState();
    if (slug || id) {
      // find articles by slug or id

      let result;
      if (id) {
        let find = findItemFromIdWithParent(id, children);
        result = find && find[1];
      } else if (slug) {
        result = findItemFromSlug(slug, children, false);
      }

      if (result) {
        const newsIds = [result.id];

        // dispatch action to open news
        dispatch(newsOpenFromIds(newsIds));
        // dispatch action to update url
        dispatch(updateUrl({ url: result.url, type: result.type }));
      } else {
        // check if detail exist
        dispatch(
          fetchNewsBySlug(getCurrentLocale(), slug, id, {
            success: (newsDetail) => {
              dispatch(newsOpenFromIds([newsDetail.id]));
              const d = new Date(newsDetail.date);
              dispatch(newsArchivesSetYear(d.getFullYear()));
            },
            error: (error) => {
              dispatch(newsOpenError(error));
            },
          })
        );
      }
    }
  };
}

export function updateUrl({ url, type }) {
  return function (dispatch) {
    dispatch({ type: types.UPDATE_NEWS_URL });
    dispatch(goTo(routerConfig.newsDetail.buildUrl({ slug: url, type })));
  };
}

export function newsArchivesSetYear(year) {
  return function (dispatch) {
    dispatch({ type: types.NEWS_ARCHIVES_SET_YEAR, year });
  };
}
