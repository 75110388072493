export const Article = {
  title: "title",
  resume: "rich text",
  body: "richText",
  url: "slug",
  position: "number",
  codename: "internal codename usefull for mapping",
  id: "util no ?",
};

const kontentArticleToArticle = (kontentArticle) => {
  const result = {};
  Object.keys(Article).map((prop) => {
    if (kontentArticle[prop] && kontentArticle[prop].value) {
      result[prop] = kontentArticle[prop].value;
    }
    if (kontentArticle.system && kontentArticle.system[prop]) {
      result[prop] = kontentArticle.system[prop];
    }
  });
  return result;
};

const sortByPosition = (a, b) => {
  let comparison = 0;
  const aPosition = !a.position ? 0 : parseInt(a.position, 10);
  const bPosition = !b.position ? 0 : parseInt(b.position, 10);
  if (aPosition > bPosition) {
    comparison = 1;
  } else if (aPosition < bPosition) {
    comparison = -1;
  }
  return comparison;
};

export const resolveResponseData = (items) => {
  const children = items
    // find root
    .filter((item) => item.parent.value.length === 0)
    // convert
    .map(kontentArticleToArticle)
    //sort by position
    .sort(sortByPosition)
    .map((rootItemFormatted) => {
      // find children
      const children = items
        .filter(
          (item) =>
            item.parent.itemCodenames.length > 0 &&
            item.parent.itemCodenames[0] === rootItemFormatted.codename
        )
        // convert
        .map(kontentArticleToArticle)
        // sort children by position
        .sort(sortByPosition);

      // add to root item
      return {
        ...rootItemFormatted,
        children,
      };
    });
  return {
    children,
  };
};
