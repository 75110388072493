export const FETCH_DATA_PENDING = "FETCH_DATA_PENDING";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_ERROR = "FETCH_DATA_ERROR";

export const ARTICLE_TOGGLE_STATE = "ARTICLE_TOGGLE_STATE";
export const ARTICLE_TOGGLE_EXPAND = "ARTICLE_TOGGLE_EXPAND";
export const ARTICLE_CLOSE_ALL = "ARTICLE_CLOSE_ALL";

export const SUBSCRIBE_PENDING = "SUBSCRIBE_PENDING";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";

export const FETCH_NEWS_BY_SLUG_PENDING = "FETCH_NEWS_BY_SLUG_PENDING";
export const FETCH_NEWS_BY_SLUG_SUCCESS = "FETCH_NEWS_BY_SLUG_SUCCESS";
export const FETCH_NEWS_BY_SLUG_ERROR = "FETCH_NEWS_BY_SLUG_ERROR";

export const FETCH_NEWS_PENDING = "FETCH_NEWS_PENDING";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_ERROR = "FETCH_NEWS_ERROR";

export const FETCH_NEWS_ARCHIVES_PENDING = "FETCH_NEWS_ARCHIVES_PENDING";
export const FETCH_NEWS_ARCHIVES_SUCCESS = "FETCH_NEWS_ARCHIVES_SUCCESS";
export const FETCH_NEWS_ARCHIVES_ERROR = "FETCH_NEWS_ARCHIVES_ERROR";

export const FETCH_INFORMATIONS_PENDING = "FETCH_INFORMATIONS_PENDING";
export const FETCH_INFORMATIONS_SUCCESS = "FETCH_INFORMATIONS_SUCCESS";
export const FETCH_INFORMATIONS_ERROR = "FETCH_INFORMATIONS_ERROR";

export const ARTICLE_OPEN_SUCCESS = "ARTICLE_OPEN_SUCCESS";
export const ARTICLE_OPEN_ERROR = "ARTICLE_OPEN_ERROR";
export const ARTICLE_OPEN_INIT = "ARTICLE_OPEN_INIT";

export const UPDATE_ARTICLE_URL = "UPDATE_ARTICLE_URL";
export const UPDATE_NEWS_URL = "UPDATE_NEWS_URL";
export const UPDATE_INFORMATION_URL = "UPDATE_INFORMATION_URL";
export const UPDATE_CANDIDACY_URL = "UPDATE_CANDIDACY_URL";

export const INFORMATION_TOGGLE_STATE = "INFORMATION_TOGGLE_STATE";
export const INFORMATION_TOGGLE_EXPAND = "INFORMATION_TOGGLE_EXPAND";
export const INFORMATION_CLOSE_ALL = "INFORMATION_CLOSE_ALL";
export const INFORMATION_OPEN_SUCCESS = "INFORMATION_OPEN_SUCCESS";
export const INFORMATION_OPEN_ERROR = "INFORMATION_OPEN_ERROR";
export const INFORMATION_OPEN_INIT = "INFORMATION_OPEN_INIT";

export const NEWS_TOGGLE_STATE = "NEWS_TOGGLE_STATE";
export const NEWS_TOGGLE_EXPAND = "NEWS_TOGGLE_EXPAND";
export const NEWS_CLOSE_ALL = "NEWS_CLOSE_ALL";
export const NEWS_OPEN_SUCCESS = "NEWS_OPEN_SUCCESS";
export const NEWS_OPEN_ERROR = "NEWS_OPEN_ERROR";
export const NEWS_OPEN_INIT = "NEWS_OPEN_INIT";

export const NEWS_ARCHIVES_SET_YEAR = "NEWS_ARCHIVES_SET_YEAR";

export const FETCH_CANDIDACIES_PENDING = "FETCH_CANDIDACIES_PENDING";
export const FETCH_CANDIDACIES_SUCCESS = "FETCH_CANDIDACIES_SUCCESS";
export const FETCH_CANDIDACIES_ERROR = "FETCH_CANDIDACIES_ERROR";

export const CANDIDACY_TOGGLE_STATE = "CANDIDACY_TOGGLE_STATE";
export const CANDIDACY_TOGGLE_EXPAND = "CANDIDACY_TOGGLE_EXPAND";
export const CANDIDACY_CLOSE_ALL = "CANDIDACY_CLOSE_ALL";
export const CANDIDACY_OPEN_SUCCESS = "CANDIDACY_OPEN_SUCCESS";
export const CANDIDACY_OPEN_ERROR = "CANDIDACY_OPEN_ERROR";
