import { getCurrentLocale } from "../utils/Locale.js";
export const newsPageLinks = {
  fr: "/news/plus-rien-ne-nous-retient-dans-ce-pays?lang=fr",
  en: "/news/nothing-is-holding-us-back-in-this-country-by-ferdinand-richard?lang=en",
  ar: "/news/nothing-is-holding-us-back-in-this-country?lang=ar",
};

export const mapArticleIdToArVersion = {
  // beneficiaires - mobilite
  "634638fe-0799-43b9-8937-712f6fea117e":
    "17ad7c2c-b4d9-4f00-aacd-7e4b37478c25",
  //beneficiaire - zodeh
  "358f2dde-9560-464a-8045-38c72ca744a7":
    "4b745ea8-5fcc-42d1-a4ff-34b38670c916",
  // solidarity fund ana houna
  "b705d6b9-9263-42d4-9dd7-249aeedef8f9":
    "5a8ef58a-f83d-47a7-81ae-ddbc0ec4f418",
  // solidarity sha
  "78eb44df-352f-45ac-be02-f88e38839010":
    "cea597ca-3088-4b5f-ac69-d4f0bd068944",
};

/**
 * TODO refactor copy paste stuff
 */
export const routerConfig = {
  home: {
    path: "/",
    buildUrl: ({ locale } = { locale: getCurrentLocale() }) => {
      return `${routerConfig.home.path}?lang=${locale}`;
    },
    isActiveMenu: (path) =>
      path === routerConfig.home.path ||
      path === routerConfig.articleDetail.path,
  },
  news: {
    path: "/news/:type?",
    buildUrl: (
      { locale, type } = { locale: getCurrentLocale(), type: "fanak" }
    ) => {
      return `/news/${type}?lang=${locale ? locale : getCurrentLocale()}`;
    },
    isActiveMenu: (path, isMatchType) => {
      return (
        (path === "/news/" ||
          path === routerConfig.news.path ||
          path === routerConfig.newsDetail.path) &&
        isMatchType
      );
    },
  },
  candidacy: {
    path: "/candidacy/",
    buildUrl: ({ locale } = { locale: getCurrentLocale() }) => {
      return `${routerConfig.candidacy.path}?lang=${locale}`;
    },
    isActiveMenu: (path) =>
      path === routerConfig.candidacy.path ||
      path === routerConfig.candidacy.path,
  },
  comments: {
    path: "/comments/",
    buildUrl: ({ locale } = { locale: getCurrentLocale() }) => {
      return `${routerConfig.comments.path}?lang=${locale}`;
    },
    isActiveMenu: (path) =>
      path === routerConfig.comments.path ||
      path === routerConfig.commentDetail.path,
  },
  informations: {
    path: "/informations/",
    buildUrl: ({ locale } = { locale: getCurrentLocale() }) => {
      return `${routerConfig.informations.path}?lang=${locale}`;
    },
    isActiveMenu: (path) =>
      path === routerConfig.informations.path ||
      path === routerConfig.informationDetail.path,
  },
  articleDetail: {
    path: `/:slug`,
    buildUrl: (
      { locale, slug, id } = { locale: getCurrentLocale(), slug: "", id: "" }
    ) => {
      if (id && id !== "") {
        return `/?id=${id}&lang=${locale ? locale : getCurrentLocale()}`;
      }
      return `/${slug}/?lang=${locale ? locale : getCurrentLocale()}`;
    },
  },
  newsDetail: {
    path: "/news/:type/:slug",
    buildUrl: (
      { locale, type, slug, id } = {
        locale: getCurrentLocale(),
        type: "fanak",
        slug: "",
        id: "",
      }
    ) => {
      if (id) {
        return `/news/${type ? type : "fanak"}/?lang=${
          locale ? locale : getCurrentLocale()
        }&id=${id}`;
      } else {
        return `/news/${type ? type : "fanak"}/${slug}/?lang=${
          locale ? locale : getCurrentLocale()
        }`;
      }
    },
  },
  candidacyDetail: {
    path: `/candidacy/:slug`,
  },
  commentDetail: { path: `/comments/:slug` },
  informationDetail: {
    path: `/informations/:slug`,
    buildUrl: (
      { locale, slug, id } = { locale: getCurrentLocale(), slug: "", id: "" }
    ) => {
      if (id) {
        return `${routerConfig.informations.path}?lang=${
          locale ? locale : getCurrentLocale()
        }&id=${id}`;
      } else {
        return `${routerConfig.informations.path}${slug}/?lang=${
          locale ? locale : getCurrentLocale()
        }`;
      }
    },
  },
  formQuali: {
    path: `/form-quali/`,
    buildUrl: (
      { locale, email, id } = { locale: getCurrentLocale(), email: "", id: "" }
    ) => {
      return `${routerConfig.formQuali.path}?lang=${locale}&email=${email}&id=${id}`;
    },
  },
};
