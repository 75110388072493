// Centralized propType definitions
import {
  string,
  arrayOf,
  shape,
  func,
  object,
  instanceOf,
  bool,
  oneOfType,
  number,
} from "prop-types";

export const Article = {
  title: string.isRequired,
  resume: string.isRequired,
  body: string.isRequired,
  url: string.isRequired,
  codename: string.isRequired,
  id: string.isRequired,
  children: arrayOf(shape(Article)),
};

export const Item = {
  title: string.isRequired,
  resume: string,
  body: string.isRequired,
  url: string,
  codename: string,
  id: string.isRequired,
  children: arrayOf(shape(Item)),
};

export const Info = {
  name: string.isRequired,
  body: string.isRequired,
  id: string.isRequired,
  title: string.isRequired,
};

export const ArticleState = shape({
  expanded: arrayOf(string),
  opened: arrayOf(string),
});

export const ArticleDispatch = shape({
  toggleState: func,
  toggleExpand: func,
});

export const News = {
  title: string.isRequired,
  photo: arrayOf(object),
  date: instanceOf(Date),
  resume: string,
  body: string.isRequired,
  url: string.isRequired,
  codename: string.isRequired,
  id: string.isRequired,
  type: string.isRequired,
};

export const Candidacy = {
  title: string.isRequired,
  resume: string,
  body: string.isRequired,
  position: number,
  url: string,
  id: string.isRequired,
};

export const ItemConfig = shape({
  isAllwaysExpanded: bool.isRequired,
  backgroundColors: oneOfType([arrayOf(string), object]),
  seaPictureKey: oneOfType([string, object]),
  id: string,
  isAllwaysOpened: bool,
});
