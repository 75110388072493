import {
  SUBSCRIBE_PENDING,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_ERROR
} from "../constants/actionTypes";
import objectAssign from "object-assign";
import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function contentDataReducer(
  state = initialState.newsletter,
  action
) {
  switch (action.type) {
    case SUBSCRIBE_PENDING:
      // For this example, just simulating a save by changing date modified.
      // In a real app using Redux, you might use redux-thunk and handle the async call in fuelSavingsActions.js
      return objectAssign({}, state, { pending: true, success: false });

    case SUBSCRIBE_SUCCESS:
      return objectAssign({}, state, {
        pending: false,
        success: true,
        error: null
      });

    case SUBSCRIBE_ERROR:
      return objectAssign({}, state, {
        pending: false,
        error: action.error
      });

    default:
      return state;
  }
}
