import React from "react";
import propTypes from "prop-types";
import { Route } from "react-router-dom";

const RouteWrapper = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

RouteWrapper.propTypes = {
  component: propTypes.oneOfType([propTypes.func, propTypes.object]),
  layout: propTypes.func,
};
export default RouteWrapper;
