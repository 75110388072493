import * as types from "../constants/actionTypes";
import { routerConfig } from "../constants/url";
import { goTo } from "./urlActions";
import { findItemFromIdWithParent, findItemFromSlug } from "../utils/Url";

// example of a thunk using the redux-thunk middleware
export function toggleState(articleId) {
  return function (dispatch) {
    dispatch({
      type: types.INFORMATION_TOGGLE_STATE,
      articleId,
    });
  };
}

export function toggleExpand(articleId) {
  return function (dispatch) {
    dispatch({
      type: types.INFORMATION_TOGGLE_EXPAND,
      articleId,
    });
  };
}

export function closeAll() {
  return function (dispatch) {
    dispatch({
      type: types.INFORMATION_CLOSE_ALL,
    });
  };
}

export function informationOpenFromIds(articleIds) {
  return function (dispatch) {
    dispatch({
      type: types.INFORMATION_OPEN_SUCCESS,
      articleIds,
    });
  };
}

export function informationOpenError(error) {
  return function (dispatch) {
    dispatch({
      type: types.INFORMATION_OPEN_ERROR,
      error,
    });
  };
}

export function informationOpenInit() {
  return function (dispatch) {
    dispatch({
      type: types.INFORMATION_OPEN_INIT,
    });
  };
}

export function informationOpen(slug, id) {
  return function (dispatch, getState) {
    const {
      contentData: {
        infos: { children },
      },
    } = getState();
    if (slug || id) {
      // find articles by slug or id
      let results;
      if (id) {
        let find = findItemFromIdWithParent(id, children);
        results = find && find[1];
      } else if (slug) {
        results = findItemFromSlug(slug, children, false);
      }

      if (results) {
        const informationIds = [results.id];

        // dispatch action to open informations
        dispatch(informationOpenFromIds(informationIds));
        // dispatch action to update url
        dispatch(updateUrl({ url: results.url }));
      } else {
        // not found error
        dispatch(informationOpenError(new Error("Information not found")));
      }
    }
  };
}

export function updateUrl({ url }) {
  return function (dispatch) {
    dispatch({ type: types.UPDATE_INFORMATION_URL });
    dispatch(goTo(routerConfig.informationDetail.buildUrl({ slug: url })));
  };
}
