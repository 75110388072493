import React from "react";
import propTypes from "prop-types";
import {
  Article as ArticleType,
  Info as InfoType,
  Candidacy as CandidacyType,
  ArticleState,
  ArticleDispatch,
  ItemConfig,
} from "../../types/index.js";
import {
  shouldComponentMemo,
  isItemIdInArray,
  resolveBackgroundColor,
} from "../../utils/ItemPanel";
import { renderSquares } from "../common/Square/functions.jsx";
import SubPanel from "./SubPanel.jsx";
import ItemKontentText from "./ItemKontentText.jsx";
import ClosePanel from "./ClosePanel.jsx";
import Newsletter from "../containers/Newsletter.jsx";

const Panel = ({ item, panelState, panelDispatch, config }) => {
  const level = item.position;
  const isOpened =
    isItemIdInArray(item.id, panelState.opened) || config.isAllwaysOpened;
  const clickPanelTitle = (e) => {
    e.preventDefault();
    panelDispatch.toggleState(item.id);
    if (!isOpened && panelDispatch.updateUrl && item.url) {
      panelDispatch.updateUrl(item);
    }
  };
  const resolveBgIndex = (subIndex) => {
    if (level === 1) {
      return subIndex + 1;
    }
    if (level === 2) {
      return subIndex + 4;
    }
    if (level === 3) {
      return subIndex + 7;
    }
    if (level === 4) {
      return subIndex + 10;
    }
  };
  return (
    <div
      className={`article-panel ArticlePanel ArticlePanel--${level}`}
      id={`article-${item.id}`}
      style={{ backgroundColor: resolveBackgroundColor(config, item) }}
    >
      {renderSquares(config, item, 1)}
      <div
        className={`article-panel-content article-panel-content--${level} ArticlePanelContent ${
          isOpened ? "opened" : ""
        } ${
          config.isDisplayedWithResume && item.position !== 5
            ? "with-resume"
            : ""
        }`}
      >
        <div className="ArticlePanelTitle article-panel-title">
          <a href="#" onClick={clickPanelTitle}>
            {item.title}
          </a>
          {isOpened && (
            <ClosePanel
              item={item}
              panelState={panelState}
              panelDispatch={panelDispatch}
              classNameIdentifier="Article"
            />
          )}
        </div>
        <div
          className={`text-wrapper ${
            config.id === "information" && level === 2 && "newsletter"
          }`}
        >
          <ItemKontentText
            item={item}
            panelState={panelState}
            panelDispatch={panelDispatch}
            classNameIdentifier="Article"
            config={config}
            clickResume={clickPanelTitle}
          />
          {config.id === "information" && level === 2 && (
            <div className="NewsletterForm">
              <Newsletter />
            </div>
          )}
        </div>

        <div className="children">
          {item.children &&
            item.children.length > 0 &&
            item.children.map((subItem, index) => (
              <SubPanel
                item={subItem}
                key={subItem.id}
                panelDispatch={panelDispatch}
                panelState={panelState}
                bgIndex={resolveBgIndex(index)}
                config={config}
              ></SubPanel>
            ))}
        </div>
      </div>
      {renderSquares(config, item, -1)}
    </div>
  );
};

Panel.propTypes = {
  item: propTypes.oneOfType([
    propTypes.shape(ArticleType),
    propTypes.shape(InfoType),
    propTypes.shape(CandidacyType),
  ]),
  panelState: ArticleState,
  panelDispatch: ArticleDispatch,
  config: ItemConfig,
};

export default React.memo(Panel, shouldComponentMemo);
