import {
  INFORMATION_TOGGLE_STATE,
  INFORMATION_TOGGLE_EXPAND,
  INFORMATION_CLOSE_ALL,
  INFORMATION_OPEN_SUCCESS,
  INFORMATION_OPEN_ERROR,
  INFORMATION_OPEN_INIT,
} from "../constants/actionTypes";
import objectAssign from "object-assign";
import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function informationReducer(
  state = initialState.article,
  action
) {
  let newOpened;
  let newExpanded;
  let findedIndex;

  switch (action.type) {
    case INFORMATION_TOGGLE_STATE:
      newOpened = [...state.opened];
      findedIndex = state.opened.findIndex(
        (articleId) => articleId === action.articleId
      );
      if (findedIndex > -1) {
        // on le supprime
        newOpened.splice(findedIndex, 1);
      } else {
        //on l'ajoute
        newOpened.push(action.articleId);
      }
      return objectAssign({}, state, { opened: newOpened });

    case INFORMATION_TOGGLE_EXPAND:
      newExpanded = [...state.expanded];
      findedIndex = state.expanded.findIndex(
        (articleId) => articleId === action.articleId
      );
      if (findedIndex > -1) {
        // on le supprime
        newExpanded.splice(findedIndex, 1);
      } else {
        //on l'ajoute
        newExpanded.push(action.articleId);
      }

      return objectAssign({}, state, { expanded: newExpanded });

    case INFORMATION_CLOSE_ALL:
      return objectAssign({}, state, { expanded: [], opened: [] });

    case INFORMATION_OPEN_SUCCESS:
      newExpanded = [...state.expanded];
      newOpened = [...state.opened];

      // on expand
      action.articleIds.forEach((id) => newExpanded.push(id));

      // on ouvre
      action.articleIds.forEach((id) => newOpened.push(id));

      return objectAssign({}, state, {
        expanded: newExpanded,
        opened: newOpened,
        slugError: null,
        slugPending: false,
      });

    case INFORMATION_OPEN_ERROR:
      return objectAssign({}, state, {
        slugError: action.error,
        slugPending: false,
      });
    case INFORMATION_OPEN_INIT:
      return objectAssign({}, state, {
        slugError: null,
        slugPending: true,
      });
    default:
      return state;
  }
}
