import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { fetchData } from "../../actions/contentDataActions";
import {
  toggleState,
  toggleExpand,
  closeAll,
  updateUrl,
} from "../../actions/articleActions";
import MainPanel from "../Panel/index.jsx";
import Loader from "../Loader.jsx";
import { getCurrentLocale } from "../../utils/Locale.js";
import { getQueryParam } from "../../utils/Url.js";
import { FormattedMessage, IntlProvider } from "react-intl";
import { ArticleState } from "../../types/index.js";

import messages_fr from "../../translations/fr.json";
import messages_en from "../../translations/en.json";
import messages_ar from "../../translations/ar.json";

const messages = {
  fr: messages_fr,
  en: messages_en,
  ar: messages_ar,
};
export class HomePage extends React.Component {
  componentDidMount() {
    const language = getCurrentLocale();
    const articleId = getQueryParam("id");
    const {
      match: {
        params: { slug },
      },
    } = this.props;
    this.props.fetchData(language, slug, articleId);
  }

  componentDidUpdate(prevProps) {
    const language = getCurrentLocale();
    const articleId = getQueryParam("id");
    const {
      match: {
        params: { slug },
      },
      error,
      pending,
      articles,
      articleState: { opened },
      slugPending,
    } = this.props;
    const {
      match: {
        params: { slug: prevSlug },
      },
      slugPending: prevSlugPending,
      pending: prevPending,
    } = prevProps;

    if (!error && articles && articles.children) {
      if ((!pending && prevPending) || (!slugPending && prevSlugPending)) {
        const qs =
          slug && opened[0] ? `#article-${opened[0]}` : ".HomePageTitle";
        setTimeout(() => {
          document.querySelector(qs).scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    }

    // this fired only when slugError have been launched
    if (prevSlug !== slug && prevProps.slugError) {
      this.props.fetchData(language, slug, articleId);
    }
    // this fired only if id qp is provided

    if (!pending && articleId && !error) {
      this.props.fetchData(language, slug, articleId);
    }
  }

  render() {
    return (
      <IntlProvider
        locale={getCurrentLocale()}
        messages={messages[getCurrentLocale()]}
      >
        <div className={`HomePageContent ${this.props.pending && "pending"}`}>
          {this.props.pending && (
            <div className="HomePageLoading">
              <Loader />
            </div>
          )}
          {(this.props.error || this.props.slugError) && (
            <div className="Error">
              <h2>
                <FormattedMessage id="fannac.error" />
              </h2>
              <p>{this.props.error && this.props.error.toString()}</p>
              <p>{this.props.slugError && this.props.slugError.toString()}</p>
              <p>
                {this.props.slugError && (
                  <FormattedMessage id="fanak.slugerror" />
                )}
              </p>
              <p>
                {this.props.slugError && (
                  <a href={`/?lang=${getCurrentLocale()}`}>
                    <FormattedMessage id="fanak.gotowebsite" />
                  </a>
                )}
              </p>
            </div>
          )}
          {!this.props.error &&
            !this.props.slugError &&
            !this.props.pending &&
            this.props.articles &&
            this.props.articles.children && (
              <MainPanel
                data={this.props.articles}
                panelState={this.props.articleState}
                panelDispatch={{
                  toggleState: this.props.toggleState,
                  toggleExpand: this.props.toggleExpand,
                  updateUrl: this.props.updateUrl,
                }}
                config={this.props.articles.itemConfig}
              />
            )}
        </div>
      </IntlProvider>
    );
  }
}

HomePage.propTypes = {
  fetchData: propTypes.func.isRequired,
  articles: propTypes.object.isRequired,
  pending: propTypes.bool.isRequired,
  error: propTypes.object,
  history: propTypes.object,
  articleState: ArticleState,
  toggleExpand: propTypes.func,
  toggleState: propTypes.func,
  closeAll: propTypes.func,
  match: propTypes.shape({
    params: propTypes.shape({ slug: propTypes.string }),
  }),
  slugError: propTypes.object,
  updateUrl: propTypes.func,
  slugPending: propTypes.bool,
};
export default connect(
  ({ contentData, article }) => ({
    articles: contentData.articles,
    pending: contentData.articlesPending,
    error: contentData.articlesError,
    articleState: article,
    slugError: article.slugError,
    slugPending: article.slugPending,
  }),
  { fetchData, toggleState, toggleExpand, closeAll, updateUrl }
)(HomePage);
