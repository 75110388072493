import React from "react";
import propTypes from "prop-types";
import { Item as ItemType, ArticleDispatch } from "../../types/index.js";
import cross from "./assets/cross.svg";
import { extractTextFromHtmlString } from "../../utils/Text.js";

const ClosePanel = ({ item, panelDispatch, classNameIdentifier }) => {
  if (!item.resume || !extractTextFromHtmlString(item.resume)) {
    return null;
  }
  const clickCrossButton = (e) => {
    e.preventDefault();
    panelDispatch.toggleState(item.id);
  };
  return (
    <div className={`CloseArticle CloseArticle--${classNameIdentifier}`}>
      <a href="#" onClick={clickCrossButton} className="CloseArticleBtn">
        <img src={cross} className="CloseArticleCross" />
      </a>
    </div>
  );
};

ClosePanel.propTypes = {
  item: propTypes.shape(ItemType),
  panelDispatch: ArticleDispatch,
  classNameIdentifier: propTypes.string,
};

export default ClosePanel;
