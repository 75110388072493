import * as types from "../constants/actionTypes";
import { getCurrentLocale } from "../utils/Locale.js";

import config from "../config";

// kontentDelivery (and also rxjs) is external for browser compatibility
const { ajax } = window["rxjs"] || {};

// example of a thunk using the redux-thunk middleware
export function subscribe(email) {
  return function (dispatch) {
    dispatch({
      type: types.SUBSCRIBE_PENDING,
    });
    if (!ajax) {
      return dispatch({
        type: types.SUBSCRIBE_ERROR,
        error: new Error("no rxjs..."),
      });
    }
    const url = `${
      config.subscribeNewsletterEndPoint
    }?email=${email}&locale=${getCurrentLocale()}`;
    ajax.ajax
      .getJSON(url)
      .toPromise()
      .then(() => {
        return dispatch({
          type: types.SUBSCRIBE_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.SUBSCRIBE_ERROR,
          error: error,
        });
      });
  };
}
