import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { fetchNews } from "../../actions/contentDataActions";
import {
  toggleState,
  toggleExpand,
  closeAll,
  updateUrl,
} from "../../actions/newsActions";
import MainPanel from "../Panel/index.jsx";
import Loader from "../Loader.jsx";
import { getCurrentLocale } from "../../utils/Locale.js";
import { FormattedMessage, IntlProvider } from "react-intl";
import { ArticleState } from "../../types/index.js";

import messages_fr from "../../translations/fr.json";
import messages_en from "../../translations/en.json";
import messages_ar from "../../translations/ar.json";
import { getQueryParam } from "../../utils/Url";

const messages = {
  fr: messages_fr,
  en: messages_en,
  ar: messages_ar,
};
export class NewsPage extends React.Component {
  componentDidMount() {
    const language = getCurrentLocale();
    const newsId = getQueryParam("id");
    const {
      match: {
        params: { type, slug },
      },
    } = this.props;
    this.props.fetchNews(language, type, slug, newsId);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { type },
      },
      error,
      pending,
      slugPending,
      news,
      newsState: { opened },
    } = this.props;
    const {
      match: {
        params: { type: prevType },
      },
      slugPending: prevSlugPending,
      pending: prevPending,
    } = prevProps;
    const language = getCurrentLocale();

    if (!error && news && news.children) {
      if ((!pending && prevPending) || (!slugPending && prevSlugPending)) {
        const qs =
          type && opened[0] ? `#article-${opened[0]}` : ".HomePageTitle";
        setTimeout(() => {
          document.querySelector(qs).scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    }

    if (prevType !== type) {
      this.props.fetchNews(language, type);
    }
  }

  render() {
    return (
      <IntlProvider
        locale={getCurrentLocale()}
        messages={messages[getCurrentLocale()]}
      >
        <div className={`HomePageContent ${this.props.pending && "pending"}`}>
          {this.props.pending && (
            <div className="HomePageLoading">
              <Loader />
            </div>
          )}
          {(this.props.error || this.props.slugError) && (
            <div className="Error">
              <h2>
                <FormattedMessage id="fannac.error" />
              </h2>
              <p>{this.props.error && this.props.error.toString()}</p>
              <p>{this.props.slugError && this.props.slugError.toString()}</p>
              <p>
                {this.props.slugError && (
                  <FormattedMessage id="fanak.slugerror" />
                )}
              </p>
              <p>
                {this.props.slugError && (
                  <a href={`/?lang=${getCurrentLocale()}`}>
                    <FormattedMessage id="fanak.gotowebsite" />
                  </a>
                )}
              </p>
            </div>
          )}
          {!this.props.error &&
            !this.props.slugError &&
            !this.props.pending &&
            this.props.news &&
            this.props.news.children && (
              <MainPanel
                data={this.props.news}
                panelState={this.props.newsState}
                panelDispatch={{
                  toggleState: this.props.toggleState,
                  toggleExpand: this.props.toggleExpand,
                  updateUrl: this.props.updateUrl,
                }}
                config={this.props.news.itemConfig}
              />
            )}
        </div>
      </IntlProvider>
    );
  }
}

NewsPage.propTypes = {
  fetchNews: propTypes.func.isRequired,
  news: propTypes.object.isRequired,
  pending: propTypes.bool.isRequired,
  error: propTypes.object,
  history: propTypes.object,
  newsState: ArticleState,
  toggleExpand: propTypes.func,
  toggleState: propTypes.func,
  closeAll: propTypes.func,
  match: propTypes.shape({
    params: propTypes.shape({ type: propTypes.string, slug: propTypes.string }),
  }),
  slugError: propTypes.object,
  slugPending: propTypes.bool,
  updateUrl: propTypes.func,
};
export default connect(
  ({ contentData, news }) => ({
    news: contentData.news,
    pending: contentData.newsPending,
    error: contentData.newsError,
    newsState: news,
    slugError: news.slugError,
    slugPending: news.slugPending,
  }),
  { fetchNews, toggleState, toggleExpand, closeAll, updateUrl }
)(NewsPage);
