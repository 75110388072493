import {
  NEWS_TOGGLE_STATE,
  NEWS_TOGGLE_EXPAND,
  NEWS_CLOSE_ALL,
  NEWS_OPEN_SUCCESS,
  NEWS_OPEN_ERROR,
  NEWS_OPEN_INIT,
} from "../constants/actionTypes";
import objectAssign from "object-assign";
import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function newsReducer(state = initialState.news, action) {
  let newOpened;
  let newExpanded;
  let findedIndex;

  switch (action.type) {
    case NEWS_TOGGLE_STATE:
      newOpened = [...state.opened];
      findedIndex = state.opened.findIndex(
        (newsId) => newsId === action.newsId
      );
      if (findedIndex > -1) {
        // on le supprime
        newOpened.splice(findedIndex, 1);
      } else {
        //on l'ajoute
        newOpened.push(action.newsId);
      }
      return objectAssign({}, state, { opened: newOpened });

    case NEWS_TOGGLE_EXPAND:
      newExpanded = [...state.expanded];
      findedIndex = state.expanded.findIndex(
        (newsId) => newsId === action.newsId
      );
      if (findedIndex > -1) {
        // on le supprime
        newExpanded.splice(findedIndex, 1);
      } else {
        //on l'ajoute
        newExpanded.push(action.newsId);
      }

      return objectAssign({}, state, { expanded: newExpanded });

    case NEWS_CLOSE_ALL:
      return objectAssign({}, state, { expanded: [], opened: [] });

    case NEWS_OPEN_SUCCESS:
      newExpanded = [...state.expanded];
      newOpened = [...state.opened];

      // on expand
      action.newsIds.forEach((id) => newExpanded.push(id));

      // on ouvre
      action.newsIds.forEach((id) => newOpened.push(id));

      return objectAssign({}, state, {
        expanded: newExpanded,
        opened: newOpened,
        slugError: null,
        slugPending: false,
      });

    case NEWS_OPEN_ERROR:
      return objectAssign({}, state, {
        slugError: action.error,
        slugPending: false,
      });
    case NEWS_OPEN_INIT:
      return objectAssign({}, state, {
        slugError: null,
        slugPending: true,
      });
    default:
      return state;
  }
}
