import React from "react";
import propTypes from "prop-types";
import {
  Article as ArticleType,
  ArticleState,
  ArticleDispatch,
  ItemConfig,
} from "../../types/index.js";
import { isItemIdInArray } from "../../utils/ItemPanel";
import { renderSubSquares } from "../common/Square/functions.jsx";
import ItemKontentText from "./ItemKontentText.jsx";
import ClosePanel from "./ClosePanel.jsx";

const SubPanel = ({ item, panelState, panelDispatch, bgIndex, config }) => {
  const level = item.position;
  const isOpened = isItemIdInArray(item.id, panelState.opened);
  const clickPanelTitle = (e) => {
    e.preventDefault();
    panelDispatch.toggleState(item.id);
    if (!isOpened && panelDispatch.updateUrl) {
      panelDispatch.updateUrl(item);
    }
  };
  return (
    <div
      className={`article-panel SubArticlePanel SubArticlePanel--${level} subbg--${bgIndex}`}
      id={`article-${item.id}`}
    >
      {renderSubSquares(level, bgIndex)}
      <div
        className={`article-panel-content article-panel-content--${level} SubArticlePanelContent ${
          isOpened && "opened"
        }`}
      >
        <div className="SubArticlePanelTitle article-panel-title">
          <a href="#" onClick={clickPanelTitle}>
            {item.title}
          </a>
          {isOpened && (
            <ClosePanel
              item={item}
              panelState={panelState}
              panelDispatch={panelDispatch}
              classNameIdentifier={`SubArticle--${level}`}
            />
          )}
        </div>
        <ItemKontentText
          item={item}
          panelState={panelState}
          panelDispatch={panelDispatch}
          classNameIdentifier="SubArticle"
          config={config}
        />
      </div>
      {renderSubSquares(level, bgIndex, -1)}
    </div>
  );
};

SubPanel.propTypes = {
  item: propTypes.shape(ArticleType),
  panelState: ArticleState,
  panelDispatch: ArticleDispatch,
  bgIndex: propTypes.number,
  config: ItemConfig,
};

export default SubPanel;
