const defaultKontentProject = {
  kontentProjectId: "145a24b7-0697-0049-8239-541efc00c0bc",
  kontentPreviewsApi: {
    primaryKey:
      "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjUzOTIxZTEwYzY2NjQ2NTJhNWY1MTBkZmU3ODFiMTI2IiwNCiAgImlhdCI6ICIxNTc1MzY5Njg1IiwNCiAgImV4cCI6ICIxOTIwOTY5Njg1IiwNCiAgInByb2plY3RfaWQiOiAiMTQ1YTI0YjcwNjk3MDA0OTgyMzk1NDFlZmMwMGMwYmMiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.1oyrAoU8GBeuHteL2zc3MzUbC8u13Z-K-Ub1XvJk7oQ",
    secondaryKey:
      "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjkyMDUzNzFkZDIxNDQyN2M5NjJkNDZhNGI4ZTg5NzZkIiwNCiAgImlhdCI6ICIxNTc1MzY5Njg1IiwNCiAgImV4cCI6ICIxOTIwOTY5Njg1IiwNCiAgInByb2plY3RfaWQiOiAiMTQ1YTI0YjcwNjk3MDA0OTgyMzk1NDFlZmMwMGMwYmMiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.bGC29mvB1PVXWHv2pMj6sYJpxdTGbboZESdqesYfJ0E",
  },
};
const arKontentProject = {
  kontentProjectId: "c041d290-e053-014e-86f2-207806347c02",
  kontentPreviewsApi: {
    primaryKey:
      "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImM0ZWY5MDZmZjZiZDQ4ZTM4NDAzMTBkYTQxNzRkMmNiIiwNCiAgImlhdCI6ICIxNTkzNzUzMTY3IiwNCiAgImV4cCI6ICIxOTM5MzUzMTY3IiwNCiAgInByb2plY3RfaWQiOiAiYzA0MWQyOTBlMDUzMDE0ZTg2ZjIyMDc4MDYzNDdjMDIiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.C2QwACzBVdjDVNsIFwt2BkX4V2BPaLLuf9jIF3dKZN4",
    secondaryKey:
      "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjBmOGVlYWIzNDBkYzQ5YTVhYmQ1OTg5NmRmM2ZmYjgwIiwNCiAgImlhdCI6ICIxNTkzNzUzMTY3IiwNCiAgImV4cCI6ICIxOTM5MzUzMTY3IiwNCiAgInByb2plY3RfaWQiOiAiYzA0MWQyOTBlMDUzMDE0ZTg2ZjIyMDc4MDYzNDdjMDIiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.7WEHNNoz4-ySy4BxH7C6aN8hmQiM84ykpUXtcEJrrnA",
  },
};
export default {
  subscribeNewsletterEndPoint: "/subscribeNewsletter/index.php",
  kontent: {
    fr: defaultKontentProject,
    en: defaultKontentProject,
    ar: arKontentProject,
  },
};
