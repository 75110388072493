import { mapArticleIdToArVersion, routerConfig } from "../constants/url.js";

export const getQueryParam = (param) => {
  const queryParams = resolveQueryParams();
  return queryParams[param];
};

const resolveQueryParams = () => {
  var oParametre = {};

  if (window.location.search.length > 1) {
    for (
      var aItKey,
        nKeyId = 0,
        aCouples = window.location.search.substr(1).split("&");
      nKeyId < aCouples.length;
      nKeyId++
    ) {
      aItKey = aCouples[nKeyId].split("=");
      oParametre[unescape(aItKey[0])] =
        aItKey.length > 1 ? unescape(aItKey[1]) : "";
    }
  }
  return oParametre;
};

export const findKeyOfMapArticleIdToArVersion = (id) => {
  for (const attr in mapArticleIdToArVersion) {
    if (mapArticleIdToArVersion[attr] === id) {
      return attr;
    }
  }
  return undefined;
};

export const findItemFromSlug = (slug, collection, withParent = false) => {
  if (Array.isArray(collection)) {
    const l = collection.length;
    for (let i = 0; i < l; i++) {
      let item = collection[i];
      if (item.url === slug) {
        return withParent ? [undefined, item] : item;
      }
      if (Array.isArray(item.children)) {
        const sl = item.children.length;
        for (let j = 0; j < sl; j++) {
          const subItem = item.children[j];
          if (subItem.url === slug) {
            return withParent ? [item, subItem] : subItem;
          }
        }
      }
    }
  }
  return undefined;
};

export const findItemFromIdWithParent = (id, collection) => {
  if (Array.isArray(collection)) {
    const l = collection.length;
    for (let i = 0; i < l; i++) {
      let item = collection[i];
      if (item.id === id) {
        return [undefined, item];
      }
      if (Array.isArray(item.children)) {
        const sl = item.children.length;
        for (let j = 0; j < sl; j++) {
          const subItem = item.children[j];
          if (subItem.id === id) {
            return [item, subItem];
          }
        }
      }
    }
  }
  return undefined;
};

export const findConfigRouterKeyFromPath = (path) => {
  return Object.keys(routerConfig).find(
    (key) => routerConfig[key].path === path
  );
};
