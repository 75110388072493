import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeLocaleAndRedirect } from "../../actions/urlActions.js";
import propTypes from "prop-types";
import HomePageTop from "../common/HomePageTop.jsx";
import Menu from "../common/Menu.jsx";
import { getCurrentLocale, canSwitchLanguage } from "../../utils/Locale.js";
import { IntlProvider } from "react-intl";

import messages_fr from "../../translations/fr.json";
import messages_en from "../../translations/en.json";
import messages_ar from "../../translations/ar.json";

const messages = {
  fr: messages_fr,
  en: messages_en,
  ar: messages_ar,
};

const Layout = ({ children, match }) => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const clickMenu = (show) => {
    setShowMenu(show);
  };
  const onClickSwitchLanguage = (language) => {
    if (canSwitchLanguage(language)) {
      dispatch(changeLocaleAndRedirect(language, match));
    }
    clickMenu(false);
  };
  return (
    <IntlProvider
      locale={getCurrentLocale()}
      messages={messages[getCurrentLocale()]}
    >
      <div
        className={`HomePage HomePage_${getCurrentLocale()}`}
        dir={getCurrentLocale() === "ar" ? "rtl" : "ltr"}
      >
        <HomePageTop clickMenu={clickMenu} showMenu={showMenu} />

        <div className={`HomePage__contentWrapper ${showMenu ? "opened" : ""}`}>
          {children}
        </div>

        <Menu
          clickSwitchLanguage={onClickSwitchLanguage}
          match={match}
          showMenu={showMenu}
          clickLinkItem={() => clickMenu(false)}
        />
      </div>
    </IntlProvider>
  );
};

Layout.propTypes = {
  children: propTypes.node,
  history: propTypes.object,
  match: propTypes.object,
};
export default Layout;
