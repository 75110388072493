export default {
  contentData: {
    articles: {
      itemConfig: {
        isAllwaysExpanded: false,
        backgroundColors: [
          "#DBE2E6",
          "#C3D8E8",
          "#A9CBEB",
          "#91BFE8",
          "#7CB3E2",
        ],
        seaPictureKey: "fund",
        id: "fund",
      },
    },
    articlesPending: false,
    articlesError: null,

    newsDetail: {},
    newsDetailPending: false,
    newsDetailError: null,
    news: {
      itemConfig: {
        isAllwaysExpanded: true,
        backgroundColors: {
          fanak: ["#DAE4E0", "#C1DBDD", "#A9D2D9", "#8FC7D4", "#76BDD3"],
          partner: ["#DBE3E3", "#C3D8E2", "#A9CEE1", "#90C3E1", "#77B8E2"],
        },
        seaPictureKey: {
          fanak: "fanak",
          partner: "partner",
        },
        isDisplayedWithResume: true,
        id: "news",
      },
    },
    newsPending: false,
    newsError: null,
    newsArchives: {},
    newsArchivesPending: false,
    newsArchivesError: null,
    newsArchivesYear: null,

    infos: {
      itemConfig: {
        isAllwaysExpanded: true,
        backgroundColors: [
          "#DAE7D7",
          "#C2DFCB",
          "#A8D5BD",
          "#92CCB1",
          "#78C3A3",
        ],
        seaPictureKey: "information",
        id: "information",
      },
    },
    infosPending: false,
    infosError: null,

    candidacies: {
      itemConfig: {
        isAllwaysExpanded: true,
        isAllwaysOpened: true,
        backgroundColors: [
          "#DBE4DC",
          "#C2DCD7",
          "#A9D3D1",
          "#8ECAC9",
          "#77C2C3",
        ],
        seaPictureKey: "candidacy",
        id: "candidacy",
      },
    },
    candidaciesPending: false,
    candidaciesError: null,
  },
  article: {
    opened: [],
    expanded: [],
    slugError: null,
    slugPending: false,
  },
  information: {
    opened: [],
    expanded: [],
    slugError: null,
  },
  candidacy: {
    opened: [],
    expanded: [],
    slugError: null,
  },
  news: {
    opened: [],
    expanded: [],
    slugError: null,
    slugPending: false,
  },
  newsletter: {
    pending: false,
    error: null,
    success: false,
  },
};
