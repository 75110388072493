export const Candidacy = {
  title: "title",
  resume: "",
  body: "richText",
  position: "number",
  url: "",
  id: "util no ?",
};

const kontentCandidacyToCandidacy = (kontentInfo) => {
  const result = {};
  Object.keys(Candidacy).map((prop) => {
    if (kontentInfo[prop] && kontentInfo[prop].value) {
      result[prop] = kontentInfo[prop].value;
    }
    if (kontentInfo.system && kontentInfo.system[prop]) {
      result[prop] = kontentInfo.system[prop];
    }
  });
  return result;
};

export const resolveResponseData = (items) => {
  const children = items
    // convert
    .map(kontentCandidacyToCandidacy);
  return { children };
};
