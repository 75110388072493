import * as types from "../constants/actionTypes";
import { routerConfig } from "../constants/url";
import { goTo } from "./urlActions";
import { findItemFromIdWithParent, findItemFromSlug } from "../utils/Url";

// example of a thunk using the redux-thunk middleware
export function toggleState(articleId) {
  return function (dispatch) {
    dispatch({
      type: types.CANDIDACY_TOGGLE_STATE,
      articleId,
    });
  };
}

export function toggleExpand(articleId) {
  return function (dispatch) {
    dispatch({
      type: types.CANDIDACY_TOGGLE_EXPAND,
      articleId,
    });
  };
}

export function closeAll() {
  return function (dispatch) {
    dispatch({
      type: types.CANDIDACY_CLOSE_ALL,
    });
  };
}

export function candidacyOpenFromIds(articleIds) {
  return function (dispatch) {
    dispatch({
      type: types.CANDIDACY_OPEN_SUCCESS,
      articleIds,
    });
  };
}

export function candidacyOpenError(error) {
  return function (dispatch) {
    dispatch({
      type: types.CANDIDACY_OPEN_ERROR,
      error,
    });
  };
}

export function candidacyOpen(slug, id) {
  return function (dispatch, getState) {
    const {
      contentData: {
        articles: { children },
      },
    } = getState();
    if (slug || id) {
      // find articles by slug or id
      let results;
      if (id) {
        let find = findItemFromIdWithParent(id, children);
        results = find && find[1];
      } else if (slug) {
        results = findItemFromSlug(slug, children, true);
      }

      if (results) {
        const articleIds = [results[1].id];
        if (results[0]) {
          articleIds.push(results[0].id);
        }
        // dispatch action to open articles
        dispatch(candidacyOpenFromIds(articleIds));
        // dispatch action to update url
        dispatch(updateUrl({ url: results[1].url }));
      } else {
        // not found error
        dispatch(candidacyOpenError(new Error("Candidacy not found")));
      }
    }
  };
}

export function updateUrl(slug) {
  return function (dispatch) {
    dispatch({ type: types.UPDATE_CANDIDACY_URL });
    dispatch(goTo(routerConfig.candidacyDetail.buildUrl({ slug })));
  };
}
