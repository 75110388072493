import { getCurrentLocale } from "../utils/Locale";

import messages_fr from "../translations/fr.json";
import messages_en from "../translations/en.json";
import messages_ar from "../translations/ar.json";

const messages = {
  fr: messages_fr,
  en: messages_en,
  ar: messages_ar,
};

export const News = {
  title: "title",
  photo: "full array of assets object",
  date: "published date in Date format",
  resume: "rich text",
  body: "richText",
  url: "slug",
  codename: "internal codename usefull for mapping",
  id: "util no ?",
  type: "type of news",
};

const kontentNewsToNews = (kontentNews) => {
  const result = {};
  Object.keys(News).map((prop) => {
    if (kontentNews[prop] && kontentNews[prop].value) {
      result[prop] = Array.isArray(kontentNews[prop].value)
        ? // fine if codename exist on first item else this is a photo
          kontentNews[prop].value[0].codename || kontentNews[prop].value[0]
        : kontentNews[prop].value;
    } else if (kontentNews.system && kontentNews.system[prop]) {
      result[prop] = kontentNews.system[prop];
    }
  });
  return result;
};

export const resolveResponseData = (items) => {
  let position = 0;
  const children = items
    // convert
    .map(kontentNewsToNews)
    .map((item) => {
      // add position property
      return Object.assign({}, item, { position: ++position });
    });
  // fake item for news - can generate error on props if model change

  children.push({
    position: 5,
    title:
      messages[getCurrentLocale()] &&
      messages[getCurrentLocale()]["fanak.newsArchivesTitle"]
        ? messages[getCurrentLocale()]["fanak.newsArchivesTitle"]
        : "Archives",
    type: children[0].type,
    resume: "",
    body: "",
    url: "",
    codename: "archives",
    id: "archive",
  });
  return { children };
};

export const resolveNewsArchiveData = (items) => {
  const results = {};
  items
    // convert
    .map(kontentNewsToNews)
    .map(({ id, date, title }, index) => {
      // not the 4 first entries
      if (index > 3) {
        const myDate = new Date(date);
        const news = {
          id,
          title,
        };
        const year = myDate.getFullYear();
        if (results[year]) {
          results[year].push(news);
        } else {
          results[year] = [news];
        }
      }
    });
  return results;
};

export const resolveResponseDataDetail = (items) => {
  let result = {};
  items
    // convert
    .map(kontentNewsToNews)
    .map((newsFormatted) => {
      result = newsFormatted;
    });
  return result;
};
