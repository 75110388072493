import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { subscribe } from "../../actions/newsletterAction";
import { FormattedMessage } from "react-intl";

class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.inputEmail = React.createRef();
  }
  submitForm = (e) => {
    e.preventDefault();
    const email =
      this.inputEmail &&
      this.inputEmail.current &&
      this.inputEmail.current.value;
    if (email) {
      this.props.subscribe(email);
    }
  };

  render() {
    return (
      <form action="#" onSubmit={this.submitForm}>
        <label htmlFor="email"></label>
        <div>
          <FormattedMessage id="fannac.informations.newsletter.placeholder">
            {(placeholder) => (
              <input
                type="email"
                ref={this.inputEmail}
                placeholder={placeholder}
                id="email"
                required
              />
            )}
          </FormattedMessage>
          <input type="submit" value="OK" />
        </div>
        <div className="messages">
          {this.props.pending && (
            <FormattedMessage id="fannac.newsletter.pending" />
          )}
          {this.props.error && (
            <span className="errorMessage">
              <FormattedMessage id="fannac.newsletter.error" />
              {this.props.error.toString()}
            </span>
          )}
          {this.props.success && (
            <FormattedMessage id="fannac.newsletter.success" />
          )}
        </div>
      </form>
    );
  }
}

Newsletter.propTypes = {
  success: propTypes.bool.isRequired,
  pending: propTypes.bool.isRequired,
  error: propTypes.object,
  subscribe: propTypes.func.isRequired,
};
export default connect(
  ({ newsletter }) => ({
    success: newsletter.success,
    pending: newsletter.pending,
    error: newsletter.error,
  }),
  { subscribe }
)(Newsletter);
