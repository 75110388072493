export const Info = {
  title: "title",
  name: "string as type identifier",
  body: "richText",
  position: "number",
  url: "url",
  id: "util no ?",
};

const kontentInfoToInfo = (kontentInfo) => {
  const result = {};
  Object.keys(Info).map((prop) => {
    if (kontentInfo[prop] && kontentInfo[prop].value) {
      result[prop] = kontentInfo[prop].value;
    }
    if (kontentInfo.system && kontentInfo.system[prop]) {
      result[prop] = kontentInfo.system[prop];
    }
  });
  return result;
};

export const resolveResponseData = (items) => {
  const children = items
    // convert
    .map(kontentInfoToInfo);
  return { children };
};
