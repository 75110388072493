import * as types from "../constants/actionTypes";
import { resolveResponseData as resolveArticleData } from "../models/Article";
import {
  resolveResponseDataDetail as resolveNewsDataDetail,
  resolveResponseData as resolveNewsData,
  resolveNewsArchiveData,
} from "../models/News";
import { resolveResponseData as resolveInformationsData } from "../models/Info";
import { resolveResponseData as resolveCandidatiesData } from "../models/Candidacy";
import { articleOpen, articleOpenInit } from "./articleActions";
import { newsOpen, newsOpenInit, updateUrl } from "./newsActions.js";
import { informationOpen, informationOpenInit } from "./informationActions";
import config from "../config";

// kontentDelivery (and also rxjs) is external for browser compatibility
const { DeliveryClient } = window["kontentDelivery"] || {};

function getDeliveryClient(language) {
  const { host } = window.location;
  const subdomain = host.split(".")[0];
  const deliveryClientConfig = {
    projectId: config.kontent[language].kontentProjectId,
  };
  if (subdomain === "preview") {
    deliveryClientConfig.previewApiKey =
      config.kontent[language].kontentPreviewsApi.primaryKey;
    deliveryClientConfig.globalQueryConfig = {
      usePreviewMode: true, // Queries the Delivery Preview API.
    };
  }
  return new DeliveryClient(deliveryClientConfig);
}

export function fetchData(language, slug, articleId) {
  return function (dispatch) {
    dispatch({
      type: types.FETCH_DATA_PENDING,
    });
    if (!DeliveryClient) {
      return dispatch({
        type: types.FETCH_DATA_ERROR,
        error: new Error("Network Error"),
      });
    }
    // thunks allow for pre-processing actions, calling apis, and dispatching multiple actions
    // in this case at this point we could call a service that would persist the fuel savings

    const deliveryClient = getDeliveryClient(language);

    deliveryClient
      .items()
      .type(["article"])
      .languageParameter(language)
      .toPromise()
      .then((response) => {
        // todo filter items by type
        const { items } = response;
        const data = {
          articles: {},
        };

        data.articles = resolveArticleData(items);

        dispatch({
          type: types.FETCH_DATA_SUCCESS,
          data: data,
        });

        if (slug || articleId) {
          dispatch(articleOpenInit());
          dispatch(articleOpen(slug, articleId));
        }
      })
      .catch((error) => {
        return dispatch({
          type: types.FETCH_DATA_ERROR,
          error: error,
        });
      });
  };
}

export function fetchNewsBySlug(language, slug, id, callbacks) {
  return function dispatch(dispatch) {
    dispatch({
      type: types.FETCH_NEWS_BY_SLUG_PENDING,
    });
    if (!DeliveryClient) {
      return dispatch({
        type: types.FETCH_NEWS_BY_SLUG_ERROR,
        error: new Error("Network Error"),
      });
    }

    const deliveryClient = getDeliveryClient(language);

    const equalsFilter = id ? ["system.id", id] : ["elements.url", slug];
    deliveryClient
      .items()
      .type(["news"])
      .languageParameter(language)
      .equalsFilter(equalsFilter[0], equalsFilter[1])
      .toPromise()
      .then((response) => {
        const { items } = response;
        if (!items || items.length === 0) {
          throw new Error("404 - Page not found");
        }
        const data = {
          newsDetail: {},
        };
        data.newsDetail = resolveNewsDataDetail(items);

        dispatch(
          updateUrl({ url: data.newsDetail.url, type: data.newsDetail.type })
        );
        dispatch({
          type: types.FETCH_NEWS_BY_SLUG_SUCCESS,
          data: data,
        });

        if (
          callbacks &&
          callbacks.success &&
          typeof callbacks.success === "function"
        ) {
          callbacks.success(data.newsDetail);
        }
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_NEWS_BY_SLUG_ERROR,
          error: error,
        });
        if (
          callbacks &&
          callbacks.error &&
          typeof callbacks.error === "function"
        ) {
          callbacks.error(error);
        }
      });
  };
}

export function fetchNewsArchives(language, type) {
  return function dispatch(dispatch) {
    dispatch({
      type: types.FETCH_NEWS_ARCHIVES_PENDING,
    });
    if (!DeliveryClient) {
      return dispatch({
        type: types.FETCH_NEWS_ARCHIVES_ERROR,
        error: new Error("Network Error"),
      });
    }
    const deliveryClient = getDeliveryClient(language);

    const request = deliveryClient
      .itemsFeedAll()
      .type("news")
      .languageParameter(language)
      .orderByDescending("elements.date")
      .elementsParameter(["title", "date"]);

    if (type) {
      request.containsFilter("elements.type", [type]);
    }

    request
      .toPromise()
      .then((response) => {
        const { items } = response;
        if (!items || items.length === 0) {
          return dispatch({
            type: types.FETCH_NEWS_ARCHIVES_ERROR,
            error: new Error("No news !"),
          });
        }
        const data = {
          newsArchives: {},
        };
        data.newsArchives = resolveNewsArchiveData(items);
        //dispatch(updateNewsUrl(data.newsDetail.url));
        return dispatch({
          type: types.FETCH_NEWS_ARCHIVES_SUCCESS,
          data: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.FETCH_NEWS_ARCHIVES_ERROR,
          error: error,
        });
      });
  };
}
export function fetchNews(language, type, slug, id) {
  return function dispatch(dispatch) {
    dispatch({
      type: types.FETCH_NEWS_PENDING,
    });
    if (!DeliveryClient) {
      return dispatch({
        type: types.FETCH_NEWS_ERROR,
        error: new Error("Network Error"),
      });
    }
    const deliveryClient = getDeliveryClient(language);

    const request = deliveryClient
      .items()
      .type(["news"])
      .languageParameter(language)
      .orderByDescending("elements.date")
      .limitParameter(4);

    if (type) {
      request.containsFilter("elements.type", [type]);
    }

    request
      .toPromise()
      .then((response) => {
        const { items } = response;
        if (!items || items.length === 0) {
          return dispatch({
            type: types.FETCH_NEWS_ERROR,
            error: new Error("No news !"),
          });
        }
        const data = {
          news: {},
        };
        data.news = resolveNewsData(items);

        dispatch({
          type: types.FETCH_NEWS_SUCCESS,
          data: data,
        });

        if (slug || id) {
          dispatch(newsOpenInit());
          dispatch(newsOpen(slug, id));
        }
      })
      .catch((error) => {
        return dispatch({
          type: types.FETCH_NEWS_ERROR,
          error: error,
        });
      });
  };
}

export function fetchInformations(language, slug, informationId) {
  return function (dispatch) {
    dispatch({
      type: types.FETCH_INFORMATIONS_PENDING,
    });
    if (!DeliveryClient) {
      return dispatch({
        type: types.FETCH_INFORMATIONS_ERROR,
        error: new Error("Network Error"),
      });
    }
    // thunks allow for pre-processing actions, calling apis, and dispatching multiple actions
    // in this case at this point we could call a service that would persist the fuel savings

    const deliveryClient = getDeliveryClient(language);

    deliveryClient
      .items()
      .type(["info"])
      .languageParameter(language)
      .orderByAscending("elements.position")
      .toPromise()
      .then((response) => {
        // todo filter items by type
        const { items } = response;
        const data = {
          informations: {},
        };

        data.informations = resolveInformationsData(items);

        dispatch({
          type: types.FETCH_INFORMATIONS_SUCCESS,
          data: data,
        });

        if (slug || informationId) {
          dispatch(informationOpenInit());
          dispatch(informationOpen(slug, informationId));
        }
      })
      .catch((error) => {
        return dispatch({
          type: types.FETCH_INFORMATIONS_ERROR,
          error: error,
        });
      });
  };
}

export function fetchCandidacies(language) {
  return function (dispatch) {
    dispatch({
      type: types.FETCH_CANDIDACIES_PENDING,
    });
    if (!DeliveryClient) {
      return dispatch({
        type: types.FETCH_CANDIDACIES_ERROR,
        error: new Error("Network Error"),
      });
    }
    // thunks allow for pre-processing actions, calling apis, and dispatching multiple actions
    // in this case at this point we could call a service that would persist the fuel savings

    const deliveryClient = getDeliveryClient(language);

    deliveryClient
      .items()
      .type(["candidacy"])
      .languageParameter(language)
      .orderByAscending("elements.position")
      .toPromise()
      .then((response) => {
        const { items } = response;
        const data = {
          candidacies: {},
        };

        data.candidacies = resolveCandidatiesData(items);

        return dispatch({
          type: types.FETCH_CANDIDACIES_SUCCESS,
          data: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.FETCH_CANDIDACIES_ERROR,
          error: error,
        });
      });
  };
}
