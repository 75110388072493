import { combineReducers } from "redux";
import contentData from "./contentDataReducer";
import article from "./articleReducer";
import information from "./informationReducer";
import newsletter from "./newsletterReducer";
import news from "./newsReducer";
import candidacy from "./candidacyReducer";

import { connectRouter } from "connected-react-router";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    contentData,
    article,
    information,
    newsletter,
    news,
    candidacy,
  });

export default rootReducer;
