import { push } from "connected-react-router";
import {
  findConfigRouterKeyFromPath,
  findItemFromSlug,
  findKeyOfMapArticleIdToArVersion,
} from "../utils/Url";
import { getCurrentLocale } from "../utils/Locale";
import { routerConfig, mapArticleIdToArVersion } from "../constants/url";

const detailsToHome = {
  newsDetail: "news",
  informationDetail: "informations",
  commentDetail: "comments",
  grantDetail: "grants",
};

// go to url location
export function goTo(url, forceRefresh = false) {
  return function dispatch(dispatch) {
    dispatch(push(url));
    if (forceRefresh === true) {
      location.reload();
    }
  };
}

export function changeLocaleAndRedirect(locale, match) {
  /**
   * 1. find which configRouter item is
   * 2. find item from slug if exist with the appropriate collection else redirect to root configRouter item
   * 3. if arab and not articleDetail router config, redirect to root configRouter item
   * 4. if arab and articleDetail, try to find id to redirect via mapper arab version
   * 5. if from arab and articleDetail, try to find id to redirect via mapper arab version
   * 6. finally redirect with id !
   */
  return function dispatch(dispatch, getState) {
    const {
      path,
      params: { slug, type },
    } = match;
    const {
      contentData: { articles, news, newsDetail, infos },
    } = getState();
    const currentLocale = getCurrentLocale();

    const configRouterItemKey = findConfigRouterKeyFromPath(path);
    let findedItem, targetId;

    switch (configRouterItemKey) {
      case "articleDetail":
        findedItem = findItemFromSlug(slug, articles.children);
        if (findedItem && (currentLocale === "ar" || locale === "ar")) {
          if (currentLocale === "ar") {
            if (findedItem.id) {
              targetId = findKeyOfMapArticleIdToArVersion(findedItem.id);
            }
          } else if (locale === "ar") {
            targetId = mapArticleIdToArVersion[findedItem.id];
          }
        }
        if (!targetId) {
          // redirect to findedItem
          targetId = findedItem.id;
        }
        return dispatch(
          goTo(
            routerConfig.articleDetail.buildUrl({
              locale,
              id: targetId,
            })
          )
        );

      case "newsDetail":
      case "informationDetail":
      case "commentDetail":
      case "grantDetail":
        if (currentLocale === "ar" || locale === "ar") {
          // redirect to home
          // voir plus tard pour faire un mapping d'ids pour la section informations
          return dispatch(
            goTo(
              routerConfig[detailsToHome[configRouterItemKey]].buildUrl({
                locale,
                type,
              })
            )
          );
        } else {
          // try to get the detail id and redirect

          if (configRouterItemKey === "newsDetail" && slug) {
            const newsItem = findItemFromSlug(slug, news.children, false);
            let id;
            if (newsItem) {
              id = newsItem.id;
            } else if (newsDetail && newsDetail.url === slug) {
              id = newsDetail.id;
            } else {
              // root news with type
              return dispatch(
                goTo(
                  routerConfig[configRouterItemKey].buildUrl({ locale, type }),
                  true
                )
              );
            }

            dispatch(
              goTo(
                routerConfig[configRouterItemKey].buildUrl({
                  locale,
                  type,
                  id,
                }),
                true
              )
            );
          }

          if (configRouterItemKey === "informationDetail" && slug) {
            const informationItem = findItemFromSlug(
              slug,
              infos.children,
              false
            );
            let id;
            if (informationItem) {
              id = informationItem.id;
            } else {
              // root infos
              return dispatch(
                goTo(routerConfig["informations"].buildUrl({ locale }), true)
              );
            }

            dispatch(
              goTo(
                routerConfig[configRouterItemKey].buildUrl({
                  locale,
                  id,
                }),
                true
              )
            );
          }
        }

        break;
      default:
        return dispatch(
          goTo(
            routerConfig[configRouterItemKey].buildUrl({ locale, type }),
            true
          )
        );
    }
  };
}
