import React from "react";
import propTypes from "prop-types";
import { getCurrentLocale } from "../../utils/Locale.js";
import { /*FormattedMessage,*/ IntlProvider } from "react-intl";
import FormQuali from "../FormQuali.jsx";

import messages_fr from "../../translations/fr.json";
import messages_en from "../../translations/en.json";
import messages_ar from "../../translations/ar.json";

const messages = {
  fr: messages_fr,
  en: messages_en,
  ar: messages_ar,
};
export class FormQualiPage extends React.Component {
  render() {
    return (
      <IntlProvider
        locale={getCurrentLocale()}
        messages={messages[getCurrentLocale()]}
      >
        <div className={`HomePageContent`}>
          <FormQuali />
        </div>
      </IntlProvider>
    );
  }
}

FormQualiPage.propTypes = {
  history: propTypes.object,
  match: propTypes.shape({
    params: propTypes.shape({ slug: propTypes.string }),
  }),
};
export default FormQualiPage;
