import {
  FETCH_DATA_PENDING,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_ERROR,
  FETCH_NEWS_BY_SLUG_ERROR,
  FETCH_NEWS_BY_SLUG_PENDING,
  FETCH_NEWS_BY_SLUG_SUCCESS,
  FETCH_NEWS_ERROR,
  FETCH_NEWS_PENDING,
  FETCH_NEWS_SUCCESS,
  FETCH_INFORMATIONS_PENDING,
  FETCH_INFORMATIONS_SUCCESS,
  FETCH_INFORMATIONS_ERROR,
  FETCH_NEWS_ARCHIVES_ERROR,
  FETCH_NEWS_ARCHIVES_PENDING,
  FETCH_NEWS_ARCHIVES_SUCCESS,
  NEWS_ARCHIVES_SET_YEAR,
  FETCH_CANDIDACIES_ERROR,
  FETCH_CANDIDACIES_SUCCESS,
  FETCH_CANDIDACIES_PENDING,
} from "../constants/actionTypes";
import objectAssign from "object-assign";
import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function contentDataReducer(
  state = initialState.contentData,
  action
) {
  let newState;

  switch (action.type) {
    case FETCH_DATA_PENDING:
      // For this example, just simulating a save by changing date modified.
      // In a real app using Redux, you might use redux-thunk and handle the async call in fuelSavingsActions.js
      return objectAssign({}, state, { articlesPending: true });

    case FETCH_DATA_SUCCESS:
      newState = objectAssign({}, state);
      newState.articlesPending = false;
      newState.articles = objectAssign(
        {},
        newState.articles,
        action.data.articles
      );

      return newState;

    case FETCH_DATA_ERROR:
      newState = objectAssign({}, state);
      newState.articlesPending = false;
      newState.articlesError = action.error;
      return newState;

    case FETCH_NEWS_BY_SLUG_PENDING:
      return objectAssign({}, state, { newsDetailPending: true });

    case FETCH_NEWS_BY_SLUG_SUCCESS:
      newState = objectAssign({}, state);
      newState.newsDetailPending = false;
      newState.newsDetail = action.data.newsDetail;
      newState.newsDetailError = null;
      return newState;

    case FETCH_NEWS_BY_SLUG_ERROR:
      newState = objectAssign({}, state);
      newState.newsDetailPending = false;
      newState.newsDetailError = action.error;
      return newState;

    case FETCH_NEWS_PENDING:
      return objectAssign({}, state, { newsPending: true });

    case FETCH_NEWS_SUCCESS:
      newState = objectAssign({}, state);
      newState.newsPending = false;
      newState.news = objectAssign({}, newState.news, action.data.news);
      newState.newsError = null;
      return newState;

    case FETCH_NEWS_ERROR:
      newState = objectAssign({}, state);
      newState.newsPending = false;
      newState.newsError = action.error;
      return newState;

    case FETCH_INFORMATIONS_PENDING:
      return objectAssign({}, state, { infosPending: true });

    case FETCH_INFORMATIONS_SUCCESS:
      newState = objectAssign({}, state);
      newState.infosPending = false;
      newState.infos = objectAssign(
        {},
        newState.infos,
        action.data.informations
      );
      newState.infosError = null;
      return newState;

    case FETCH_INFORMATIONS_ERROR:
      newState = objectAssign({}, state);
      newState.infosPending = false;
      newState.infosError = action.error;
      return newState;

    case FETCH_NEWS_ARCHIVES_PENDING:
      return objectAssign({}, state, { newsArchivesPending: true });

    case FETCH_NEWS_ARCHIVES_SUCCESS:
      newState = objectAssign({}, state);
      newState.newsArchivesPending = false;
      newState.newsArchives = objectAssign(
        {},
        newState.newsArchives,
        action.data.newsArchives
      );
      newState.newsArchivesError = null;
      return newState;

    case FETCH_NEWS_ARCHIVES_ERROR:
      newState = objectAssign({}, state);
      newState.newsArchivesPending = false;
      newState.newsArchivesError = action.error;
      return newState;
    case NEWS_ARCHIVES_SET_YEAR:
      newState = objectAssign({}, state);
      newState.newsArchivesYear = action.year;
      return newState;

    case FETCH_CANDIDACIES_PENDING:
      return objectAssign({}, state, { candidaciesPending: true });

    case FETCH_CANDIDACIES_SUCCESS:
      newState = objectAssign({}, state);
      newState.candidaciesPending = false;
      newState.candidacies = objectAssign(
        {},
        newState.candidacies,
        action.data.candidacies
      );
      newState.candidaciesError = null;
      return newState;

    case FETCH_CANDIDACIES_ERROR:
      newState = objectAssign({}, state);
      newState.candidaciesPending = false;
      newState.candidaciesError = action.error;
      return newState;
    default:
      return state;
  }
}
