import React from "react";
import propTypes from "prop-types";
import {
  Article as ArticleType,
  Info as InfoType,
  News as NewsType,
  Candidacy as CandidacyType,
  ArticleState,
  ArticleDispatch,
  ItemConfig,
} from "../../types/index.js";
import Panel from "./Panel.jsx";
import { renderSquares } from "../common/Square/functions.jsx";
import {
  resolveBackgroundColor,
  imageHeight,
  resolveSeaPicture,
} from "../../utils/ItemPanel.js";
import NewsArchives from "../containers/NewsArchives.jsx";

const MainPanel = ({ data, panelState, panelDispatch, config }) => {
  if (!data || !data.children || data.children.length === 0) {
    return null;
  }
  const childrenReversed = [...data.children].reverse();

  return (
    <div>
      {data &&
        data.children &&
        data.children.map((item) => (
          <Panel
            item={item}
            key={item.id}
            panelState={panelState}
            panelDispatch={panelDispatch}
            config={config}
          />
        ))}
      {data &&
        data.children &&
        childrenReversed.map((article, index) => {
          return (
            <div
              className={`HomePageContentClose HomePageContentClose--${article.position}`}
              style={{
                backgroundColor: resolveBackgroundColor(config, article),
              }}
              key={`close${article.id}`}
            >
              {renderSquares(config, article, 1)}
              <div className={`article-panel-content--${article.position}`}>
                {index === 0 && (
                  <div>
                    {config.id && config.id === "news" ? (
                      <NewsArchives config={config} news={article} />
                    ) : (
                      <img
                        src={resolveSeaPicture(config, article)}
                        className="HomePageContentCloseImage"
                        style={{
                          height: `${imageHeight(config)}px`,
                          maxHeight: "inherit",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              {renderSquares(config, article, -1)}
            </div>
          );
        })}
    </div>
  );
};

MainPanel.propTypes = {
  data: propTypes.oneOfType([
    propTypes.shape({
      children: propTypes.arrayOf(propTypes.shape(ArticleType)),
      itemConfig: ItemConfig,
    }),
    propTypes.shape({
      children: propTypes.arrayOf(propTypes.shape(InfoType)),
      itemConfig: ItemConfig,
    }),
    propTypes.shape({
      children: propTypes.arrayOf(propTypes.shape(NewsType)),
      itemConfig: ItemConfig,
    }),
    propTypes.shape({
      children: propTypes.arrayOf(propTypes.shape(CandidacyType)),
      itemConfig: ItemConfig,
    }),
  ]),
  panelState: ArticleState,
  panelDispatch: ArticleDispatch,
  config: ItemConfig,
};

export default MainPanel;
