import seaFundPicture from "../components/common/assets/fund-img.jpg";
import seaPartnerPicture from "../components/common/assets/partner-img.jpg";
import seaFanakPicture from "../components/common/assets/fanak-img.jpg";
import seaCandidacyPicture from "../components/common/assets/candidacy-img.jpg";
import seaInformationPicture from "../components/common/assets/information-img.jpg";

const seaPictureMap = {
  fund: seaFundPicture,
  partner: seaPartnerPicture,
  fanak: seaFanakPicture,
  candidacy: seaCandidacyPicture,
  information: seaInformationPicture,
};

export const isItemIdInArray = (itemId, arrayOfIds) =>
  arrayOfIds.some((id) => itemId === id);

export const propItem = (type) => (props) => {
  const { item, panelState } = props;
  return isItemIdInArray(item.id, panelState[type]);
};

export const hasChanged = (oldResult, newResult) => oldResult !== newResult;

export const filterItemChildren = (type) => (props) => {
  if (props.item.children) {
    return props.item.children.filter((item) => {
      return propItem(type)({ ...props, item: { id: item.id } });
    });
  }
  return [];
};

export const componentHasChanged = (oldProps, newProps) => (property) => {
  let shouldRender = hasChanged(
    propItem(property)(oldProps),
    propItem(property)(newProps)
  );
  if (shouldRender) {
    return true;
  }
  // check children for opening
  shouldRender = hasChanged(
    filterItemChildren(property)(oldProps).length,
    filterItemChildren(property)(newProps).length
  );

  if (shouldRender) {
    return true;
  }
  return false;
};

export const shouldComponentMemo = (oldProps, newProps) => {
  const componentFn = componentHasChanged(oldProps, newProps);
  if (componentFn("opened") || componentFn("expanded")) {
    return false;
  }
  return true;
};

export const resolveBackgroundColor = (config, item) => {
  let result = "";
  if (item.type) {
    result = config.backgroundColors[item.type][item.position - 1];
  } else {
    result = config.backgroundColors[item.position - 1];
  }
  return result;
};

export const imageHeight = (config) => {
  const elementNbr = config && config.id === "candidacy" ? 4.2 : 5;
  const { innerWidth: ww, innerHeight: wh } = window;
  const wwRatioBase = ww * (2 / 100);
  const logoTitle = wh * (12.5 / 100);
  const calculedArticleTitleHeight = wwRatioBase * 2.8;
  const articleTitleHeight =
    calculedArticleTitleHeight > 30 && calculedArticleTitleHeight < 50
      ? calculedArticleTitleHeight
      : calculedArticleTitleHeight < 30
      ? 30
      : 55;
  const homePageContentTop = articleTitleHeight * elementNbr;
  const homePageContentClose = wwRatioBase * elementNbr;
  const pageBottomHeight = ww > 600 ? wwRatioBase * 1.9 : wwRatioBase * 10;
  const heightReserved =
    logoTitle + homePageContentTop + homePageContentClose + pageBottomHeight;
  return wh - heightReserved;
};

export const resolveSeaPicture = (config, item) => {
  let key;
  if (item.type) {
    key = config.seaPictureKey[item.type];
  } else {
    key = config.seaPictureKey;
  }
  return seaPictureMap[key];
};
