import React from "react";
import propTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  Article as ArticleType,
  Info as InfoType,
  ArticleState,
  ArticleDispatch,
  ItemConfig,
  Candidacy as CandidacyType,
} from "../../types/index.js";
import {
  extractTextFromHtmlString,
  hyphenateHtmlString,
} from "../../utils/Text.js";
import { isItemIdInArray } from "../../utils/ItemPanel";

const ItemKontentText = ({
  item,
  panelState,
  panelDispatch,
  classNameIdentifier,
  config,
  clickResume,
}) => {
  const isOpened =
    isItemIdInArray(item.id, panelState.opened) || config.isAllwaysOpened;
  const isExpanded =
    config.isAllwaysExpanded || isItemIdInArray(item.id, panelState.expanded);
  const createResumeMarkup = () => ({
    __html: hyphenateHtmlString(item.resume),
  });
  const createBodyMarkup = () => ({
    __html: hyphenateHtmlString(item.body),
  });

  const clickPanelReadMore = (e) => {
    e.preventDefault();
    panelDispatch.toggleExpand(item.id);
  };

  return (
    <div>
      {item.resume && extractTextFromHtmlString(item.resume) && (
        <div
          className={`article-panel-resume ${classNameIdentifier}PanelResume ${
            config.isAllwaysExpanded ? "with-cursor" : ""
          }`}
          dangerouslySetInnerHTML={createResumeMarkup()}
          onClick={clickResume}
        />
      )}
      {!config.isAllwaysExpanded &&
        !isExpanded &&
        extractTextFromHtmlString(item.body) && (
          <p className="article-panel-read-more">
            <a href="#" onClick={clickPanelReadMore}>
              <FormattedMessage id="fannac.readMore" />
            </a>
          </p>
        )}
      {extractTextFromHtmlString(item.body) && (
        <div
          className={`article-panel-body ${classNameIdentifier}PanelBody ${
            isExpanded && isOpened ? "opened" : ""
          }`}
        >
          {item.photo && item.photo.url && (
            <img
              src={`${item.photo.url}?w=${window.innerWidth}`}
              className="article-panel-body-image"
            />
          )}
          <div dangerouslySetInnerHTML={createBodyMarkup()} />
        </div>
      )}
      {!config.isAllwaysExpanded &&
        isExpanded &&
        extractTextFromHtmlString(item.body) && (
          <p className="article-panel-read-more">
            <a href="#" onClick={clickPanelReadMore}>
              <FormattedMessage id="fannac.foldUp" />
            </a>
          </p>
        )}
    </div>
  );
};

ItemKontentText.propTypes = {
  item: propTypes.oneOfType([
    propTypes.shape(ArticleType),
    propTypes.shape(InfoType),
    propTypes.shape(CandidacyType),
  ]),
  panelState: ArticleState,
  panelDispatch: ArticleDispatch,
  classNameIdentifier: propTypes.string,
  config: ItemConfig,
  clickResume: propTypes.func,
};

export default ItemKontentText;
