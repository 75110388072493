import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCandidacies as fetchData } from "../../actions/contentDataActions";
import {
  toggleState,
  toggleExpand,
  closeAll,
} from "../../actions/candidacyActions";
import MainPanel from "../Panel/index.jsx";
import Loader from "../Loader.jsx";
import { getCurrentLocale } from "../../utils/Locale.js";
import { FormattedMessage, IntlProvider } from "react-intl";
import { ArticleState } from "../../types/index.js";

import messages_fr from "../../translations/fr.json";
import messages_en from "../../translations/en.json";
import messages_ar from "../../translations/ar.json";

const messages = {
  fr: messages_fr,
  en: messages_en,
  ar: messages_ar,
};
export class CandidacyPage extends React.Component {
  componentDidMount() {
    const language = getCurrentLocale();
    this.props.fetchData(language);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { slug },
      },
      error,
      pending,
      data,
      candidacyState: { opened },
    } = this.props;
    if (!error && !pending && data && data.children && prevProps.pending) {
      const qs = slug && opened[0] ? `#article-${opened[0]}` : ".HomePageTitle";
      setTimeout(() => {
        document.querySelector(qs).scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }
  render() {
    return (
      <IntlProvider
        locale={getCurrentLocale()}
        messages={messages[getCurrentLocale()]}
      >
        <div className={`HomePageContent ${this.props.pending && "pending"}`}>
          {this.props.pending && (
            <div className="HomePageLoading">
              <Loader />
            </div>
          )}
          {(this.props.error || this.props.slugError) && (
            <div className="Error">
              <h2>
                <FormattedMessage id="fannac.error" />
              </h2>
              <p>{this.props.error && this.props.error.toString()}</p>
              <p>{this.props.slugError && this.props.slugError.toString()}</p>
              <p>
                {this.props.slugError && (
                  <FormattedMessage id="fanak.slugerror" />
                )}
              </p>
              <p>
                {this.props.slugError && (
                  <a href={`/?lang=${getCurrentLocale()}`}>
                    <FormattedMessage id="fanak.gotowebsite" />
                  </a>
                )}
              </p>
            </div>
          )}
          {!this.props.error &&
            !this.props.pending &&
            this.props.data &&
            this.props.data.children && (
              <MainPanel
                data={this.props.data}
                panelState={this.props.candidacyState}
                panelDispatch={{
                  toggleState: this.props.toggleState,
                  toggleExpand: this.props.toggleExpand,
                }}
                config={this.props.data.itemConfig}
              />
            )}
        </div>
      </IntlProvider>
    );
  }
}

CandidacyPage.propTypes = {
  fetchData: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  pending: propTypes.bool.isRequired,
  error: propTypes.object,
  history: propTypes.object,
  candidacyState: ArticleState,
  toggleExpand: propTypes.func,
  toggleState: propTypes.func,
  closeAll: propTypes.func,
  match: propTypes.shape({
    params: propTypes.shape({ slug: propTypes.string }),
  }),
  slugError: propTypes.object,
};
export default connect(
  ({ contentData, candidacy }) => ({
    data: contentData.candidacies,
    pending: contentData.candidaciesPending,
    error: contentData.candidaciesError,
    candidacyState: candidacy,
  }),
  { fetchData, toggleState, toggleExpand, closeAll }
)(CandidacyPage);
