import React from "react";
import propTypes from "prop-types";

const Square = ({ level, cssPrefix = "bg", style }) => {
  return <div className={`Square ${cssPrefix}--${level}`} style={style}></div>;
};

Square.propTypes = {
  level: propTypes.number.isRequired,
  cssPrefix: propTypes.string,
  style: propTypes.object,
};

export default Square;
