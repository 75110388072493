import React, { useState } from "react";

const FormQuali = () => {
  const initialState = {
    first_name: "",
    last_name: "",
    lang: "",
    person: "",
    structure: "",
    phone: "",
    country: "",
    city: "",
  };
  const [state, setState] = useState(initialState);
  const handleChange = (fieldName) => (event) => {
    const nextState = { ...state };
    nextState[fieldName] = event.target.value;
    setState(nextState);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("state", state);
    return false;
  };
  return (
    <div className="form-quali">
      <h1>Dîtes nous en plus à propos de vous</h1>
      <p>
        Nous vous remercions de bien vouloir remplir ce formulaire pour mieux
        vous connaître
      </p>
      <form onSubmit={handleSubmit}>
        <select name="lang" value={state.lang} onChange={handleChange("lang")}>
          <option value="">Votre langue préféré</option>
          <option value="fr">Français</option>
          <option value="en">Anglais</option>
          <option value="ar">Arabe</option>
        </select>
        <input
          type="text"
          name="last_name"
          placeholder="Votre nom"
          value={state.last_name}
          onChange={handleChange("last_name")}
        />
        <input
          type="text"
          name="first_name"
          placeholder="Votre prénom"
          value={state.first_name}
          onChange={handleChange("first_name")}
        />
        <select
          name="person"
          value={state.person}
          onChange={handleChange("person")}
        >
          <option value="">Vous êtes</option>
          <option>avocat</option>
          <option> artiste </option>
          <option>chercheur </option>
          <option>comptable </option>
          <option>activiste droits humains</option>
          <option>interprète/traducteur</option>
          <option> indépendant</option>
        </select>
        <select
          name="structure"
          value={state.structure}
          onChange={handleChange("structure")}
        >
          <option value="">Vous faîtes partie d'une structure</option>
          <option>Administration</option>
          <option>Member of Fanak Fund</option>
          <option>Banque</option>
          <option>Centre de Recherche</option>
          <option>Point Contact Culture</option>
          <option>Collectivité territoriale</option>
          <option>Délégation de l'UE</option>
          <option>Capitale européenne de la Culture</option>
          <option>Institution européenne</option>
          <option>Fondation</option>
          <option>Hötel</option>
          <option>Institut culturel/Ambassade</option>
          <option>Institution</option>
          <option>Structure</option>
          <option>Membre du Parlement européen</option>
          <option>Ministère</option>
          <option>Réseau/Alliance/Coalition</option>
          <option>Représentant permanent</option>
          <option>Presse</option>
          <option>Restaurant</option>
          <option>Agence des Nations Unies</option>
          <option>Université</option>
        </select>
        <input
          type="text"
          name="phone"
          placeholder="Téléphone"
          value={state.phone}
          onChange={handleChange("phone")}
        />
        <input
          type="text"
          name="city"
          value={state.city}
          onChange={handleChange("city")}
          placeholder="Ville"
        />
        <input
          type="text"
          name="country"
          value={state.country}
          onChange={handleChange("country")}
          placeholder="Pays"
        />
        <input type="submit" value="Envoyer" />
      </form>
    </div>
  );
};

export default FormQuali;
