import React from "react";
import propTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { getCurrentLocale } from "../../utils/Locale.js";
import Link from "../common/Link.jsx";
import { routerConfig } from "../../constants/url.js";

const Menu = ({
  clickSwitchLanguage,
  isPageNews,
  match,
  showMenu,
  clickLinkItem,
}) => {
  const clickLanguage = (language) => (e) => {
    e.preventDefault();
    clickSwitchLanguage(language);
  };
  const {
    path,
    params: { type: newsType },
  } = match;
  return (
    <div className={`Menu ${showMenu && "Menu--display"}`}>
      <div className="left">
        {getCurrentLocale() === "ar" && (
          <>
            {!isPageNews && (
              <span className="HomePageBottom__ar-left">
                {/*<Link to={() => newsPageLinks[getCurrentLocale()]}>
                  <FormattedMessage id="fanak.news" />
            </Link>*/}
              </span>
            )}
            <div
              className={`Menu__switchLangage ${
                !isPageNews ? "HomePageBottom__switchLangage--ar" : ""
              }`}
            >
              <a onClick={clickLanguage("fr")} href="#">
                <FormattedMessage id="fannac.menuBottom.francais" />
              </a>
              <span> / </span>
              <a onClick={clickLanguage("en")} href="#">
                <FormattedMessage id="fannac.menuBottom.english" />
              </a>
            </div>
          </>
        )}
        <Link
          url={routerConfig.home.buildUrl()}
          label={<FormattedMessage id="fanak.menu.fund" />}
          className={routerConfig.home.isActiveMenu(path) ? "active" : ""}
          click={clickLinkItem}
        />
        <Link
          url={routerConfig.news.buildUrl({ type: "partner" })}
          label={<FormattedMessage id="fanak.menu.newsPartners" />}
          className={
            routerConfig.news.isActiveMenu(path, newsType === "partner")
              ? "active"
              : ""
          }
          click={clickLinkItem}
        />
        <Link
          url={routerConfig.news.buildUrl({ type: "fanak" })}
          label={<FormattedMessage id="fanak.menu.newsFanak" />}
          className={
            routerConfig.news.isActiveMenu(path, newsType === "fanak")
              ? "active"
              : ""
          }
          click={clickLinkItem}
        />
        <Link
          url={routerConfig.candidacy.buildUrl()}
          label={<FormattedMessage id="fanak.menu.candidacies" />}
          className={routerConfig.candidacy.isActiveMenu(path) ? "active" : ""}
          click={clickLinkItem}
        />
        {/*
        <Link
          url={routerConfig.comments.buildUrl()}
          label={<FormattedMessage id="fanak.menu.comments" />}
          className={path === "/comments/" ? "active" : ""}
          click={clickLinkItem}
        />
        */}
        <Link
          url={routerConfig.informations.buildUrl()}
          label={<FormattedMessage id="fanak.menu.info" />}
          className={
            routerConfig.informations.isActiveMenu(path) ? "active" : ""
          }
          click={clickLinkItem}
        />
      </div>
      <div className="right">
        {getCurrentLocale() === "fr" && (
          <>
            <a onClick={clickLanguage("en")} href="#">
              <FormattedMessage id="fannac.menuBottom.switchLang" />
            </a>
            <a onClick={clickLanguage("ar")} href="#">
              <FormattedMessage id="fannac.menuBottom.arab" />
            </a>
          </>
        )}
        {getCurrentLocale() === "en" && (
          <>
            <a onClick={clickLanguage("fr")} href="#">
              <FormattedMessage id="fannac.menuBottom.switchLang" />
            </a>
            <a onClick={clickLanguage("ar")} href="#">
              <FormattedMessage id="fannac.menuBottom.arab" />
            </a>
          </>
        )}
      </div>
    </div>
  );
};

Menu.propTypes = {
  clickLinkItem: propTypes.func,
  clickSwitchLanguage: propTypes.func.isRequired,
  isPageNews: propTypes.bool,
  match: propTypes.object.isRequired,
  showMenu: propTypes.bool,
};

export default Menu;
