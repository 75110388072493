/* eslint-disable import/no-named-as-default */
import { Switch } from "react-router-dom";
import HomePage from "./containers/HomePage.jsx";
import InformationsPage from "./containers/InformationsPage.jsx";
import CandidacyPage from "./containers/CandidacyPage.jsx";
import FormQualiPage from "./containers/FormQualiPage.jsx";
import NewsPage from "./containers/NewsPage.jsx";
import NotFoundPage from "./NotFoundPage";
import Layout from "./containers/Layout.jsx";
import { routerConfig } from "../constants/url.js";
import RouteWrapper from "./RouteWrapper.jsx";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  render() {
    return (
      <div className="layout">
        {
          <Switch>
            <RouteWrapper
              exact
              path={routerConfig.home.path}
              component={HomePage}
              layout={Layout}
            />

            <RouteWrapper
              exact
              path={routerConfig.news.path}
              component={NewsPage}
              layout={Layout}
            />

            <RouteWrapper
              exact
              path={routerConfig.informations.path}
              component={InformationsPage}
              layout={Layout}
            />
            <RouteWrapper
              exact
              path={routerConfig.candidacy.path}
              component={CandidacyPage}
              layout={Layout}
            />
            <RouteWrapper
              exact
              path={routerConfig.formQuali.path}
              component={FormQualiPage}
              layout={Layout}
            />
            <RouteWrapper
              path={routerConfig.newsDetail.path}
              component={NewsPage}
              layout={Layout}
            />
            <RouteWrapper
              exact
              path={routerConfig.informationDetail.path}
              component={InformationsPage}
              layout={Layout}
            />
            <RouteWrapper
              path={routerConfig.articleDetail.path}
              component={HomePage}
              layout={Layout}
            />
            <RouteWrapper component={NotFoundPage} layout={Layout} />
          </Switch>
        }
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
