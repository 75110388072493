import React from "react";
import propTypes from "prop-types";
import { useDispatch } from "react-redux";
import { goTo } from "../../actions/urlActions";

const Link = ({ url, label, className, click }) => {
  const dispatch = useDispatch();
  const clickLink = (url) => (e) => {
    e.preventDefault();
    if (click && typeof click === "function") {
      click();
    }
    return dispatch(goTo(url));
  };

  return (
    <a href={url} onClick={clickLink(url)} className={className}>
      {label}
    </a>
  );
};

Link.propTypes = {
  url: propTypes.string.isRequired,
  label: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
  className: propTypes.string,
  click: propTypes.func,
};

export default Link;
