import {getQueryParam} from './Url.js';


const locales = ["fr", "en", "ar"];


export const getCurrentLocale = () => {
  const navigatorLanguage = navigator.language.split(/[-_]/)[0];
  let language = "fr";
  if (locales.indexOf(navigatorLanguage) > -1) {
    language = navigatorLanguage;
  }
  const queryParam = getQueryParam('lang');
  if (queryParam && locales.indexOf(queryParam) > -1) {
    language = queryParam;
  }
  return language;
};

export const canSwitchLanguage = (language) => {
  return getCurrentLocale() !== language && locales.indexOf(language) > -1;
};
