import React from "react";
import { resolveBackgroundColor } from "../../../utils/ItemPanel.js";
import Square from "./index.jsx";

export const renderSquares = (config, item, direction = 1) => {
  let result = [];
  const level = item.position;
  if (level > 1) {
    if (direction === 1) {
      for (let i = 1; i < level; i++) {
        result.push(
          <Square
            level={i}
            key={`articlePanel${level}${i}`}
            style={{
              backgroundColor: resolveBackgroundColor(
                config,
                Object.assign({}, { ...item }, { position: i })
              ),
            }}
          />
        );
      }
    } else {
      for (let i = level; i >= 1; i--) {
        result.push(
          <Square
            level={i}
            key={`articlePanel${level}${i}inverse`}
            style={{
              backgroundColor: resolveBackgroundColor(
                config,
                Object.assign({}, { ...item }, { position: i })
              ),
            }}
          />
        );
      }
    }
  }
  return result;
};
export const renderSubSquares = (level, bgIndex, direction = 1) => {
  let result = [];
  let from =
    bgIndex < 4
      ? 1
      : bgIndex < 7 && level < 4
      ? 4
      : bgIndex < 9 && level >= 4
      ? 4
      : (level === 2 || level === 3) && (bgIndex === 11 || bgIndex === 12)
      ? 10
      : 7;
  if (level > 1) {
    if (direction === 1) {
      for (let i = from; i < from + level; i++) {
        result.push(
          <Square
            level={i}
            key={`subArticlePanel${level}${i}`}
            cssPrefix="subbg"
          />
        );
      }
    } else {
      for (let i = bgIndex; i >= from; i--) {
        result.push(
          <Square
            level={i}
            key={`subArticlePanel${level}${i}inverse`}
            cssPrefix="subbg"
          />
        );
      }
    }
  }
  return result;
};
